.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background: rgb(4,4,48);
  background: linear-gradient(315deg, rgba(4,4,48,1) 0%, rgba(51,5,59,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-small {
    margin-top: 7%;
}
